var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"font-weight-bold main--text text-heading-6"},[_vm._v(" "+_vm._s(_vm.notificationTitle)+" ")]),_c('div',{staticClass:"font-weight-regular main--text lighten-3 text-heading-1"},[_vm._v(" "+_vm._s(_vm.notificationSubTitle)+" ")])]),_c('v-data-table',{attrs:{"items":_vm.getNotifications,"footer-props":_vm.footers,"hide-default-header":"","disable-filtering":"","disable-sort":"","mobile-breakpoint":"0","items-per-page":_vm.itemsPerPage,"server-items-length":_vm.itemsPerPage},on:{"update:options":_vm.updateTable},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('ListItemNotification',{staticClass:"mx-3",class:index === 0
              ? 'mb-4'
              : index === _vm.getNotifications.length - 1
              ? 'mt-4 mb-2'
              : 'my-4',attrs:{"notification":item,"read-status":item.is_read !== 0}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }