<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <div class="font-weight-bold main--text text-heading-6">
          {{ notificationTitle }}
        </div>
        <div class="font-weight-regular main--text lighten-3 text-heading-1">
          {{ notificationSubTitle }}
        </div>
      </v-col>
      <v-data-table
        :items="getNotifications"
        :footer-props="footers"
        hide-default-header
        disable-filtering
        disable-sort
        mobile-breakpoint="0"
        :items-per-page="itemsPerPage"
        :server-items-length="itemsPerPage"
        @update:options="updateTable"
      >
        <template v-slot:item="{ item, index }">
          <ListItemNotification
            class="mx-3"
            :notification="item"
            :class="
              index === 0
                ? 'mb-4'
                : index === getNotifications.length - 1
                ? 'mt-4 mb-2'
                : 'my-4'
            "
            :read-status="item.is_read !== 0"
          />
        </template>
      </v-data-table>
    </v-row>
  </v-container>
</template>

<script>
import ListItemNotification from "@/components/ui/ListItemNotification";
import { mapGetters } from "vuex";
export default {
  name: "NotificationList",
  components: { ListItemNotification },
  data() {
    return {
      loading: false,
      itemsPerPage: 10
    };
  },
  created() {
    this.getDataFromApi();
  },
  computed: {
    ...mapGetters([
      "getNotifications",
      "getNotificationsPagination",
      "getNotificationListTitle"
    ]),
    notificationTitle() {
      if (
        this.$route.params.category_id == undefined &&
        this.$route.params.tag_id == undefined
      ) {
        return this.$t("notifications");
      } else {
        return this.getNotificationListTitle;
      }
    },
    notificationSubTitle() {
      if (
        this.$route.params.category_id == undefined &&
        this.$route.params.tag_id == undefined
      ) {
        return this.$t("notifications_subtitle");
      } else {
        return this.$t("notifications_subtitle_related");
      }
    },
    footers() {
      return {
        itemsPerPageText: this.$t("items_per_page"),
        itemsPerPageOptions: [this.itemsPerPage],
        pagination: {
          page: 1,
          itemsPerPage: this.itemsPerPage,
          pageStart: this.getNotificationsPagination.records_from - 1,
          pageStop: this.getNotificationsPagination.records_to,
          pageCount: this.getNotificationsPagination.total_pages,
          itemsLength: this.getNotificationsPagination.records_total
        },
        showCurrentPage: true
      };
    }
  },
  methods: {
    updateTable(e) {
      this.getDataFromApi(e.page);
    },
    getDataFromApi(page) {
      this.loading = true;

      let params = {
        paginate: this.itemsPerPage,
        page: page,
        sort: "post_date",
        order: "desc"
      };

      if (this.$route.params.category_id != undefined) {
        params.category_id = this.$route.params.category_id;
      }

      if (this.$route.params.tag_id != undefined) {
        params.tag_id = this.$route.params.tag_id;
      }

      this.$store
        .dispatch("NOTIFICATION_GET", {
          params: params
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style scoped>
.theme--light.v-data-table {
  background-color: transparent;
}

.theme--light.v-data-table >>> .v-data-footer {
  border: none !important;
}
</style>
